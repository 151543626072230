import React from "react"

const Logo = (props) => (
  <svg width="100%" height="100%" viewBox="0 0 443 77" version="1.1" {...props}>
      <path d="M76.7,0L3.8,0C1.7,0 0,1.6 0,3.6L0,73.2C0,75.2 1.7,76.8 3.8,76.8L76.7,76.8C78.8,76.8 80.5,75.2 80.5,73.2L80.5,3.6C80.4,1.6 78.7,0 76.7,0ZM46.6,21L55,21L55,29L46.6,29L46.6,21ZM19.9,21.3C21.3,24.3 23.1,25 26.1,25L26.1,21L34.5,21L34.5,29L26.7,29C21.9,29 18.7,27.8 16.6,23.1L19.9,21.3ZM40.7,58.1C30.1,58.1 21.4,54.8 13.7,48.4L16.7,44.6C24.3,50 30.6,52.5 40.7,52.5C50.9,52.5 57.1,50 64.7,44.6L67.7,48.4C60,54.8 51.3,58.1 40.7,58.1Z" fill="currentColor" />
      <g>
          <path d="M131.5,44.9C131.4,46.4 131.1,47.9 130.6,49.3C130.1,50.7 129.4,52 128.6,53.1C127.3,54.7 125.5,56.2 123.3,57.3C121.1,58.5 118.5,59 115.6,59C112.7,59 110.1,58.4 107.8,57.2C105.5,56 103.7,54.6 102.4,53C101,51.2 99.9,49.1 99.3,46.8C98.6,44.5 98.3,41.9 98.3,39.1C98.3,36.3 98.6,33.8 99.3,31.5C100,29.2 101,27.1 102.4,25.3C103.7,23.7 105.5,22.3 107.8,21.1C110.1,19.9 112.7,19.3 115.6,19.3C118.5,19.3 121.1,19.9 123.3,21C125.5,22.2 127.3,23.6 128.6,25.2C129.5,26.3 130.2,27.6 130.6,29C131.1,30.4 131.4,31.9 131.5,33.4L122.5,33.4C122.5,32.7 122.3,32.1 122.1,31.5C121.9,30.9 121.7,30.4 121.4,29.9C120.9,29.1 120.1,28.4 119.1,27.9C118.1,27.4 116.9,27.1 115.5,27.1C114.2,27.1 113.2,27.3 112.3,27.8C111.4,28.2 110.6,28.8 110,29.5C108.9,30.7 108.2,32.2 107.9,33.9C107.6,35.6 107.5,37.3 107.5,39.1C107.5,40.9 107.6,42.6 107.9,44.3C108.2,46 108.9,47.4 110,48.6C110.6,49.3 111.4,49.8 112.3,50.3C113.2,50.8 114.3,51 115.5,51C116.9,51 118.1,50.7 119.1,50.2C120.1,49.7 120.8,49 121.4,48.2C121.7,47.7 121.9,47.2 122.1,46.6C122.3,46 122.4,45.4 122.5,44.7L131.5,44.7L131.5,44.9Z" fill="currentColor" />
          <path d="M168.5,39.3C168.5,42.5 168.1,45.2 167.3,47.3C166.5,49.5 165.4,51.4 164,53.2C162.7,54.8 160.9,56.2 158.6,57.4C156.3,58.6 153.7,59.1 150.8,59.1C147.9,59.1 145.3,58.5 143,57.4C140.7,56.2 138.9,54.9 137.6,53.2C136.2,51.4 135.1,49.4 134.3,47.3C133.5,45.1 133.1,42.5 133.1,39.3C133.1,36.1 133.5,33.4 134.3,31.2C135.1,29 136.2,27.1 137.6,25.3C138.9,23.7 140.7,22.3 143,21.1C145.3,19.9 147.9,19.4 150.8,19.4C153.7,19.4 156.3,20 158.6,21.1C160.9,22.3 162.7,23.6 164,25.3C165.4,27.1 166.5,29.1 167.3,31.2C168.1,33.4 168.5,36.1 168.5,39.3ZM159.3,39.3C159.3,37.5 159.1,35.8 158.8,34.1C158.5,32.4 157.7,31 156.6,29.8C156,29.1 155.2,28.5 154.2,28.1C153.2,27.6 152.1,27.4 150.8,27.4C149.5,27.4 148.4,27.6 147.5,28.1C146.5,28.6 145.7,29.1 145.1,29.8C144,31 143.3,32.5 142.9,34.1C142.6,35.8 142.4,37.5 142.4,39.3C142.4,41.1 142.6,42.8 142.9,44.5C143.2,46.2 144,47.6 145.1,48.8C145.7,49.5 146.5,50.1 147.5,50.5C148.5,51 149.6,51.2 150.8,51.2C152.1,51.2 153.2,51 154.2,50.5C155.2,50 156,49.5 156.6,48.8C157.7,47.6 158.4,46.1 158.8,44.5C159.1,42.9 159.3,41.1 159.3,39.3Z" fill="currentColor" />
          <path d="M218,58.3L218,34.8C218,32.5 217.6,30.7 216.8,29.4C216,28.1 214.5,27.5 212.2,27.5C210.9,27.5 209.8,27.8 208.8,28.4C207.9,29 207.1,29.8 206.5,30.8C205.9,31.8 205.4,32.9 205.2,34.1C204.9,35.3 204.8,36.5 204.8,37.7L204.8,58.4L195.8,58.4L195.8,34.9C195.8,32.6 195.4,30.8 194.6,29.5C193.8,28.2 192.3,27.6 190,27.6C188.7,27.6 187.6,27.9 186.7,28.5C185.8,29.1 185,29.9 184.4,30.9C183.8,31.9 183.3,33 183,34.2C182.7,35.4 182.6,36.6 182.6,37.8L182.6,58.5L173.6,58.5L173.6,20.6L181.2,20.6L181.9,25.6C183,23.9 184.4,22.4 186.2,21.3C188,20.2 190.2,19.6 192.7,19.6C195.4,19.6 197.6,20.1 199.4,21.2C201.2,22.3 202.6,23.8 203.6,25.7C204.7,23.7 206.3,22.2 208.2,21.1C210.2,20 212.4,19.5 215,19.5C219.1,19.5 222.2,20.7 224.2,23.2C226.3,25.7 227.3,28.9 227.3,32.8L227.3,58.4L218,58.4L218,58.3Z" fill="currentColor" />
          <path d="M269,39.2C269,46.7 266.7,52.4 262.2,56.1C261,57.1 259.7,57.8 258.2,58.4C256.7,58.9 254.9,59.2 253,59.2C250.7,59.2 248.7,58.9 247,58.2C245.3,57.5 244,56.6 243.1,55.5L243.1,72.8L234,72.8L234,20.4L241.9,20.4L242.5,24.6C243.6,22.9 245.2,21.6 247.1,20.8C249,20 251.1,19.6 253.1,19.6C255,19.6 256.7,19.9 258.2,20.5C259.7,21.1 261.1,21.9 262.2,22.8C264.5,24.6 266.2,27 267.3,29.8C268.5,32.5 269,35.7 269,39.2ZM259.8,39.4C259.8,37.8 259.6,36.1 259.3,34.5C259,32.9 258.3,31.4 257.4,30.2C256.7,29.4 255.9,28.7 254.9,28.1C253.9,27.5 252.7,27.2 251.3,27.2C249.5,27.2 248,27.6 246.9,28.3C245.8,29.1 244.9,30 244.3,31.2C243.7,32.4 243.2,33.7 243,35.2C242.8,36.7 242.7,38.1 242.7,39.4C242.7,40.8 242.8,42.2 243,43.6C243.2,45 243.7,46.3 244.3,47.5C244.9,48.7 245.8,49.7 246.9,50.4C248,51.2 249.5,51.5 251.3,51.5C252.7,51.5 253.9,51.2 254.9,50.6C255.9,50 256.7,49.3 257.4,48.5C258.4,47.2 259,45.8 259.3,44.2C259.6,42.7 259.8,41 259.8,39.4Z" fill="currentColor" />
          <path d="M296.1,28.8C295,28.6 293.9,28.4 293,28.4C289.8,28.4 287.4,29.4 285.7,31.5C284,33.5 283.2,36.1 283.2,39.2L283.2,58.3L273.9,58.3L273.9,20.4L281.7,20.4L282.5,26C283.4,23.8 284.8,22.3 286.8,21.3C288.8,20.3 290.9,19.9 293.2,19.9C294.2,19.9 295.1,20 296,20.1L296,28.8L296.1,28.8Z" fill="currentColor" />
          <path d="M330.3,39.3C330.3,42.5 329.9,45.2 329.1,47.3C328.3,49.5 327.2,51.4 325.8,53.2C324.5,54.8 322.7,56.2 320.4,57.4C318.1,58.6 315.5,59.1 312.6,59.1C309.7,59.1 307.1,58.5 304.8,57.4C302.5,56.2 300.7,54.9 299.4,53.2C298,51.4 296.9,49.4 296.1,47.3C295.3,45.1 294.9,42.5 294.9,39.3C294.9,36.1 295.3,33.4 296.1,31.2C296.9,29 298,27.1 299.4,25.3C300.7,23.7 302.5,22.3 304.8,21.1C307.1,19.9 309.7,19.4 312.6,19.4C315.5,19.4 318.1,20 320.4,21.1C322.7,22.3 324.5,23.6 325.8,25.3C327.2,27.1 328.3,29.1 329.1,31.2C329.9,33.4 330.3,36.1 330.3,39.3ZM321.1,39.3C321.1,37.5 320.9,35.8 320.6,34.1C320.3,32.4 319.5,31 318.4,29.8C317.8,29.1 317,28.5 316,28.1C315,27.6 313.9,27.4 312.6,27.4C311.3,27.4 310.2,27.6 309.3,28.1C308.3,28.6 307.5,29.1 306.9,29.8C305.8,31 305.1,32.5 304.7,34.1C304.4,35.8 304.2,37.5 304.2,39.3C304.2,41.1 304.4,42.8 304.7,44.5C305,46.2 305.8,47.6 306.9,48.8C307.5,49.5 308.3,50.1 309.3,50.5C310.3,51 311.4,51.2 312.6,51.2C313.9,51.2 315,51 316,50.5C317,50 317.8,49.5 318.4,48.8C319.5,47.6 320.2,46.1 320.6,44.5C320.9,42.9 321.1,41.1 321.1,39.3Z" fill="currentColor" />
          <path d="M379.9,58.3L379.9,34.8C379.9,32.5 379.5,30.7 378.7,29.4C377.9,28.1 376.4,27.5 374.1,27.5C372.8,27.5 371.7,27.8 370.7,28.4C369.8,29 369,29.8 368.4,30.8C367.8,31.8 367.3,32.9 367.1,34.1C366.8,35.3 366.7,36.5 366.7,37.7L366.7,58.4L357.7,58.4L357.7,34.9C357.7,32.6 357.3,30.8 356.5,29.5C355.7,28.2 354.2,27.6 351.9,27.6C350.6,27.6 349.5,27.9 348.5,28.5C347.6,29.1 346.8,29.9 346.2,30.9C345.6,31.9 345.1,33 344.8,34.2C344.5,35.4 344.4,36.6 344.4,37.8L344.4,58.5L335.4,58.5L335.4,20.6L343,20.6L343.7,25.6C344.8,23.9 346.2,22.4 348,21.3C349.8,20.2 352,19.6 354.5,19.6C357.2,19.6 359.4,20.1 361.2,21.2C363,22.3 364.4,23.8 365.4,25.7C366.5,23.7 368.1,22.2 370,21.1C372,20 374.2,19.5 376.8,19.5C380.9,19.5 384,20.7 386,23.2C388.1,25.7 389.1,28.9 389.1,32.8L389.1,58.4L379.9,58.4L379.9,58.3Z" fill="currentColor" />
          <path d="M394.5,16.9L391.9,12.5L403.8,3.3L407.4,9.4L394.5,16.9ZM396,58.3L396,20.4L405.2,20.4L405.2,58.3L396,58.3Z" fill="currentColor" />
          <path d="M442.2,46.5C442.2,48.3 441.9,50 441.3,51.3C440.7,52.7 439.9,53.8 438.9,54.8C437.3,56.4 435.5,57.5 433.2,58.2C431,58.9 428.5,59.2 425.9,59.2C423.5,59.2 421.2,58.9 419.1,58.2C417,57.5 415.2,56.4 413.6,54.9C412.5,53.8 411.5,52.5 410.8,51C410,49.5 409.6,47.9 409.6,46.1L418.1,46.1C418.1,46.9 418.3,47.7 418.7,48.6C419.1,49.4 419.6,50.1 420.2,50.6C420.9,51.2 421.7,51.6 422.6,51.9C423.5,52.2 424.6,52.3 425.9,52.3C426.7,52.3 427.5,52.2 428.4,52.1C429.3,52 430.1,51.7 430.9,51.4C431.7,51.1 432.3,50.6 432.8,49.9C433.3,49.3 433.6,48.4 433.6,47.4C433.6,46.3 433.3,45.4 432.6,44.7C431.9,44 431,43.6 429.8,43.4C428.1,43.2 426.4,43 424.6,42.8C422.8,42.6 421.1,42.4 419.4,42C418.1,41.7 416.9,41.2 415.9,40.6C414.9,40 413.9,39.2 413.2,38.3C412.4,37.4 411.8,36.4 411.4,35.3C411,34.2 410.8,33.1 410.8,31.8C410.8,29.9 411.2,28.2 411.9,26.8C412.6,25.4 413.5,24.2 414.7,23.3C416.1,22.1 417.8,21.2 419.7,20.5C421.6,19.8 423.8,19.5 426.2,19.5C428.5,19.5 430.7,19.9 432.8,20.6C434.9,21.3 436.7,22.5 438.1,24C439.1,25.1 439.9,26.3 440.5,27.7C441.1,29 441.4,30.4 441.4,31.8L432.7,31.8C432.7,31.1 432.6,30.4 432.3,29.8C432,29.2 431.6,28.7 431.2,28.2C430.7,27.7 429.9,27.2 429.1,26.9C428.2,26.6 427.3,26.4 426.3,26.4C425.6,26.4 425,26.4 424.3,26.5C423.7,26.5 423,26.7 422.3,27C421.5,27.3 420.8,27.9 420.2,28.5C419.6,29.2 419.4,30 419.4,31C419.4,32.4 419.9,33.3 420.8,33.8C421.8,34.3 422.7,34.6 423.8,34.8C424.7,34.9 425.4,35 426,35.1C426.6,35.2 427.2,35.2 427.7,35.3C428.3,35.4 428.9,35.5 429.5,35.6C430.1,35.7 430.9,35.8 431.8,36C434.8,36.5 437.3,37.6 439.2,39.5C441.3,41.1 442.2,43.5 442.2,46.5Z" fill="currentColor" />
      </g>
  </svg>
)

export default Logo
