module.exports = {
  donate: "Donacions",
  donate_text: "Ajuda'ns a ser imparables. Fes una donació per a fer-ho possible.",
  whatsapp_header: "Canal de Whatsapp",
  whatsapp_text: "Estigues al dia de tota la informació de la campanya",
  whatsapp_instructions: "Escriu ALTA al 607 812 839 i guarda't el número als teus contactes",
  whatsapp_cta: "Subscriu-te!",
  join: "Voluntaris",
  programa: "Propostes",
  follow: "Segueix l'activitat de",
  donate_action_header: "Ajuda'ns a fer la campanya",
  donate_action_text: "Amb una simple donació, ajudes a fer Joan Ribó alcalde.",
  donate_action_cta: "Fes una donación",
  inspector_action_header: "Sigues garantia de democràcia",
  inspector_action_text: "El 26 de maig, fes-te interventor/a o apoderat/da de Compromís per a garantir que cada vot compta.",
  inspector_action_cta: "Fes-te interventor/a",
  news_header: "Notícies",
  news_more_on: "Més notícies en valencia.compromis.net",
  news_read_more: "Continua llegint...",
  events_header: "Actes"
}
